.live {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(992)) {
      flex-direction: column-reverse;
    }
  }

  &__img {
    margin-right: 3rem;
    width: 100%;
    max-width: 69.6rem;

    @media (max-width: em(1200)) {
      margin-right: 1.5rem;
    }

    @media (max-width: em(992)) {
      margin-top: 2rem;
      margin-right: initial;
    }

    img {
      border-radius: 2.5rem;
    }
  }

  &__info {
    width: 100%;
    max-width: 60rem;

    @media (max-width: em(992)) {
      max-width: initial;
    }
  }

  .section__text {
    margin-bottom: initial;
  }
}
