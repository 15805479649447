.game {
  position: relative;

  .section__bg {
    top: -50rem;
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  .section__list--mark {
    margin-top: 3rem;
    font-size: fluid-font(2.4rem, 1.8rem);
  }

  .section__item--mark {
    padding-left: 3rem;

    &::before {
      width: 0.5rem;
      height: 0.5rem;
    }
  }

  .section__item--mark + .section__item--mark {
    margin-top: 3rem;
  }

  &__info {
    margin-right: 1.5rem;
    max-width: 50rem;

    @media (max-width: em(992)) {
      margin-right: initial;
      margin-bottom: 2rem;
      max-width: initial;
    }
  }

  &__img {
    position: absolute;
    right: -40rem;
    top: 0;
    max-width: 81.8rem;
    img {
      border-radius: 2.5rem;
    }

    @media (max-width: em(992)) {
      position: relative;
      right: initial;
    }
  }
}
