$burgerMQ: em(1200);

.menu {
  &__list {
    display: flex;
    align-items: center;

    @media (max-width: $burgerMQ) {
      flex-direction: column;
      margin: auto;
      padding-block: 4rem;
    }
  }

  &__btn {
    margin-top: auto;
    width: 100%;

    @media (min-width: em(1201)) {
      display: none;
    }
  }

  &__item {
    a {
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 110%;
      color: var(--clr-default-100);
      transition: color var(--transition);

      @media (max-width: $burgerMQ) {
        display: inline-block;
        font-size: 2.4rem;
        text-align: center;
      }

      @media (hover) {
        &:hover {
          color: #8200ff;
          transition: color var(--transition);
        }
      }
    }

    &:not(:last-child) {
      margin-right: 2.5rem;

      @media (max-width: em(1200)) {
        margin-right: 1.5rem;
      }

      @media (max-width: $burgerMQ) {
        margin-right: initial;
        margin-bottom: 3rem;
      }
    }
  }

  @media (max-width: $burgerMQ) {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    overflow: auto;
    width: 100%;
    height: 100%;
    max-width: 32rem;
    background-color: var(--clr-default-900);
    transition: right var(--transition);

    &--active {
      right: 0;
      transition: right var(--transition);
    }
  }
}
