.plus {
  position: relative;
  padding-top: 6rem;

  @media (max-width: em(767)) {
    padding-top: 3rem;
  }

  .section__bg {
    top: -5rem;
  }

  .section__btn {
    display: flex;
    margin-top: 5rem;
    margin-right: auto;
    margin-left: auto;
    max-width: 30rem;

    @media (max-width: em(767)) {
      margin-top: 3rem;
    }

    @media (max-width: em(576)) {
      max-width: initial;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: em(767)) {
      flex-direction: column;
    }
  }

  &__left,
  &__right {
    flex: 0 0 49%;
  }

  &__list {
    margin-top: 3rem;
  }

  &__left {
    & + .plus__right {
      @media (max-width: em(767)) {
        margin-top: 3rem;
      }
    }
  }

  &__item {
    display: flex;
    font-size: fluid-font(2.4rem, 1.8rem);
    line-height: 150%;

    &-img {
      margin-right: 1rem;
      width: 100%;
      max-width: 4.4rem;

      @media (max-width: em(767)) {
        max-width: 2.6rem;
      }
    }
  }

  &__item + &__item {
    margin-top: 2.5rem;
  }
}
