.faq {
  &__list {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(3, 1fr);
    list-style: none;
    margin-top: 5rem;

    @media (max-width: em(1200)) {
      grid-gap: 2rem;
    }

    @media (max-width: em(992)) {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 3rem;
    }

    @media (max-width: em(576)) {
      display: block;
    }
  }

  &__item {
    p {
      margin-top: 1.5rem;
      font-size: fluid-font(2rem, 1.6rem);
    }
  }

  &__item + &__item {
    @media (max-width: em(576)) {
      margin-top: 2rem;
    }
  }
}
