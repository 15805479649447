.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.section {
  &__pt140 {
    padding-top: 14rem;

    @media (max-width: em(767)) {
      padding-top: 7rem;
    }
  }

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -10;
    max-width: 192rem;

    img {
      height: initial;
    }
  }

  &__title {
    &--small {
      font-weight: 600;
      font-size: fluid-font(3.2rem, 2.4rem);
      line-height: 120%;
    }

    &--large {
      font-weight: 700;
      font-size: fluid-font(6.8rem, 3.4rem);
    }

    &--middle {
      font-weight: 600;
      font-size: fluid-font(4.8rem, 2.8rem);
      line-height: 120%;
    }
  }

  &__text {
    position: relative;
    z-index: 5;
    margin-top: 2.5rem;
    margin-bottom: 5rem;
    font-size: fluid-font(2rem, 1.6rem);
    line-height: 160%;
    p + p {
      margin-top: 1.5rem;
    }

    @media (max-width: em(767)) {
      margin-bottom: 2.5rem;
    }
  }

  &__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-bottom: 8px solid #821278;
    border-radius: 1.5rem;
    padding-right: 3rem;
    padding-left: 3rem;
    min-width: 25.6rem;
    min-height: 7.6rem;
    font-weight: 600;
    font-size: fluid-font(2.4rem, 1.6rem);
    line-height: 80%;
    text-transform: uppercase;
    color: var(--clr-default-100);
    background-color: #b82cac;
    transition: box-shadow var(--transition);

    @media (max-width: em(576)) {
      width: 100%;
      min-width: initial;
      min-height: 6rem;
    }

    @media (hover) {
      &:hover {
        box-shadow: 1px 1px 25px rgba(140, 64, 214, 0.25), 1px 1px 24px rgba(0, 0, 0, 0.25);
        transition: box-shadow var(--transition);
      }
    }
  }

  &__content {
    overflow: auto;
    border-radius: 3rem;
    padding: 4rem 2rem;
    background-color: #69339e;

    @media (max-width: em(1200)) {
      padding: 2rem;
    }

    @media (max-width: em(992)) {
      padding: 1rem;
    }
  }

  &__table {
    overflow: hidden;
    margin-right: auto;
    margin-left: auto;
    border-collapse: collapse;
    width: 100%;
    min-width: 80rem;
    line-height: 150%;

    tbody {
      tr {
        &:last-child {
          &::before {
            bottom: 2rem;
          }
        }
      }
    }

    tr {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 2rem;
        bottom: 0;
        border-radius: 1.5rem;
        width: calc(100% - 4rem);
        height: 0.1rem;
        background-color: rgba(255, 255, 255, 0.11);

        @media (max-width: em(992)) {
          left: 1.5rem;
          width: calc(100% - 3rem);
        }
      }

      th {
        font-weight: 400;
        text-align: left;
      }

      &:first-child {
        th {
          border-top-left-radius: 2.5rem;
          border-top-right-radius: 2.5rem;
        }
      }

      &:last-child {
        td {
          border-bottom-right-radius: 2.5rem;
          border-bottom-left-radius: 2.5rem;
          padding-bottom: 4rem;
        }
      }

      td,
      th {
        vertical-align: top;
        padding: 2rem;
        &:first-child {
          background-color: #592d84;
        }

        @media (max-width: em(992)) {
          padding: 1.5rem;
        }
      }
    }
  }

  &__img {
    position: relative;
    z-index: 4;

    img {
      border-radius: 2.5rem;
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 0;
      width: 53rem;
      height: 32.8rem;
      background-color: #8200ff;
      filter: blur(14rem);
      transform: translate(-50%, -50%);
      pointer-events: none;
    }

    &-text {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 1;
      font-weight: 700;
      font-size: fluid-font(4.2rem, 2.6rem);
      text-align: center;
      -webkit-text-stroke: 0.1rem #592d84;
      text-transform: uppercase;
      color: var(--clr-default-100);
      transform: translate(-50%, -50%);
    }
  }

  &__item {
    &--mark {
      position: relative;
      padding-left: 2rem;
      font-size: fluid-font(2.4rem, 1.6rem);

      &::before {
        content: "";
        position: absolute;
        left: 0.6rem;
        top: 0.9rem;
        border-radius: 50%;
        width: 0.3rem;
        height: 0.3rem;
        background-color: var(--clr-default-100);
      }

      & + li {
        margin-top: 1.5rem;
      }
    }
  }
}

.help {
  &__list {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

.license {
  &__title,
  &__text {
    text-align: center;
  }

  &__btn {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    max-width: 30rem;

    @media (max-width: em(576)) {
      max-width: initial;
    }
  }
}

.details,
.lotteries,
.bonus,
.offer {
  .section__btn {
    display: flex;
    margin-top: 5rem;
    margin-right: auto;
    margin-left: auto;
    max-width: 3rem;

    @media (max-width: em(576)) {
      margin-top: 3rem;
      max-width: initial;
    }
  }
}

.offer {
  .section__btn {
    max-width: 40rem;
  }
}

.bonus {
  &__content {
    td,
    th {
      &:first-child {
        background-color: initial;
      }
    }
    tr {
      &::before {
        display: none;
      }
    }

    thead {
      position: relative;

      color: rgba(255, 255, 255, 0.66);

      &::before {
        content: "";
        position: absolute;
        left: 2rem;
        bottom: 0;
        z-index: 1;
        border-radius: 1.5rem;
        width: calc(100% - 4rem);
        height: 0.1rem;
        background-color: rgba(255, 255, 255, 0.11);

        @media (max-width: em(992)) {
          left: 1.5rem;
          width: calc(100% - 3rem);
        }
      }
    }
  }
}

.center {
  text-align: center;
}
