.footer {
  position: relative;
  padding-top: 8rem;
  padding-bottom: 8rem;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0.2rem;
    background-color: rgba(255, 255, 255, 0.15);
  }

  .section__bg {
    top: initial;
    bottom: 0;
  }

  @media (max-width: em(767)) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
