.promotions {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5rem;

    @media (max-width: em(992)) {
      flex-direction: column-reverse;
      margin-top: 3rem;
    }
  }

  &__img {
    max-width: 84.4rem;
  }

  &__info {
    margin-right: 1.5rem;
    max-width: 50rem;

    @media (max-width: em(992)) {
      margin-right: initial;
      margin-bottom: 2rem;
      max-width: initial;
    }
  }
}
