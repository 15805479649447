.payment {
  position: relative;

  .section__bg {
    top: 5rem;
  }

  &__title {
    margin-top: 5rem;
    margin-bottom: 3rem;

    @media (max-width: em(767)) {
      margin-top: 3rem;
    }
  }

  &__content {
    .section__table {
      min-width: 60rem;
    }

    tr {
      &::before {
        display: none;
      }
    }

    thead {
      position: relative;

      color: rgba(255, 255, 255, 0.66);

      &::before {
        content: "";
        position: absolute;
        left: 2rem;
        bottom: 0;
        z-index: 1;
        border-radius: 1.5rem;
        width: calc(100% - 4rem);
        height: 0.1rem;
        background-color: rgba(255, 255, 255, 0.11);

        @media (max-width: em(992)) {
          left: 1.5rem;
          width: calc(100% - 3rem);
        }
      }
    }
  }
}
