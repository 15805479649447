.hero {
  padding-top: 8rem;

  @media (max-width: em(767)) {
    padding-top: 4rem;
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(992)) {
      flex-direction: column-reverse;
    }
  }

  &__info {
    margin-right: 1.5rem;
    max-width: 60rem;

    @media (max-width: em(992)) {
      margin-right: initial;
      margin-bottom: 3rem;
      max-width: initial;
    }
  }

  &__img {
    width: 100%;
    max-width: 58.4rem;

    img {
      border-radius: 2.5rem;
    }
  }

  .section__btn {
    margin-top: 3rem;
  }

  &__content {
    margin-top: 10rem;

    @media (max-width: em(767)) {
      margin-top: 4rem;
    }
  }
}
