/* stylelint-disable */

/* stylelint-disable */

/* stylelint-disable scss/operator-no-unspaced */

/* stylelint-disable scss/dollar-variable-colon-newline-after */

@font-face {
  font-family: "OpenSans";
  src: url("../fonts/OpenSans-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("../fonts/OpenSans-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("../fonts/OpenSans-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

html {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  font-size: 62.5%;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  text-decoration: none;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

button {
  border: none;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
}

ul,
ol {
  list-style: none;
}

input {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.focus-visible {
  outline: auto 1px;
}

.ios .lock {
  position: relative;
}

/* Forms
   ========================================================================== */

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:root {
  --font-family: "OpenSans", sans-serif;
  --font-weight: 400;
  --content-width: 119rem;
  --container-offset: 1.5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition: 0.3s;
  --clr-default-100: #fff;
  --clr-default-400: #181818;
  --clr-default-900: #000;
}

body {
  font-size: 1.6rem;
  color: var(--clr-default-100);
  background-color: #2b0f46;
}

body.lock {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;
}

.wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition), visibility var(--transition);
}

.wrapper[data-overlay=true]::before {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition), visibility var(--transition);
}

main {
  flex-grow: 1;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--container-offset);
  padding-left: var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.section__pt140 {
  padding-top: 14rem;
}

.section__bg {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -10;
  max-width: 192rem;
}

.section__bg img {
  height: initial;
}

.section__title--small {
  font-weight: 600;
  font-size: clamp(
    2.4rem,
    2.1090909091rem + 0.9090909091vw,
    3.2rem
  );
  line-height: 120%;
}

.section__title--large {
  font-weight: 700;
  font-size: clamp(
    3.4rem,
    2.1636363636rem + 3.8636363636vw,
    6.8rem
  );
}

.section__title--middle {
  font-weight: 600;
  font-size: clamp(
    2.8rem,
    2.0727272727rem + 2.2727272727vw,
    4.8rem
  );
  line-height: 120%;
}

.section__text {
  position: relative;
  z-index: 5;
  margin-top: 2.5rem;
  margin-bottom: 5rem;
  font-size: clamp(
    1.6rem,
    1.4545454545rem + 0.4545454545vw,
    2rem
  );
  line-height: 160%;
}

.section__text p + p {
  margin-top: 1.5rem;
}

.section__btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-bottom: 8px solid #821278;
  border-radius: 1.5rem;
  padding-right: 3rem;
  padding-left: 3rem;
  min-width: 25.6rem;
  min-height: 7.6rem;
  font-weight: 600;
  font-size: clamp(
    1.6rem,
    1.3090909091rem + 0.9090909091vw,
    2.4rem
  );
  line-height: 80%;
  text-transform: uppercase;
  color: var(--clr-default-100);
  background-color: #b82cac;
  transition: box-shadow var(--transition);
}

.section__content {
  overflow: auto;
  border-radius: 3rem;
  padding: 4rem 2rem;
  background-color: #69339e;
}

.section__table {
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  border-collapse: collapse;
  width: 100%;
  min-width: 80rem;
  line-height: 150%;
}

.section__table tbody tr:last-child::before {
  bottom: 2rem;
}

.section__table tr {
  position: relative;
}

.section__table tr::before {
  content: "";
  position: absolute;
  left: 2rem;
  bottom: 0;
  border-radius: 1.5rem;
  width: calc(100% - 4rem);
  height: 0.1rem;
  background-color: rgba(255, 255, 255, 0.11);
}

.section__table tr th {
  font-weight: 400;
  text-align: left;
}

.section__table tr:first-child th {
  border-top-left-radius: 2.5rem;
  border-top-right-radius: 2.5rem;
}

.section__table tr:last-child td {
  border-bottom-right-radius: 2.5rem;
  border-bottom-left-radius: 2.5rem;
  padding-bottom: 4rem;
}

.section__table tr td,
.section__table tr th {
  vertical-align: top;
  padding: 2rem;
}

.section__table tr td:first-child,
.section__table tr th:first-child {
  background-color: #592d84;
}

.section__img {
  position: relative;
  z-index: 4;
}

.section__img img {
  border-radius: 2.5rem;
}

.section__img::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 0;
  width: 53rem;
  height: 32.8rem;
  background-color: #8200ff;
  filter: blur(14rem);
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.section__img-text {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  font-weight: 700;
  font-size: clamp(
    2.6rem,
    2.0181818182rem + 1.8181818182vw,
    4.2rem
  );
  text-align: center;
  -webkit-text-stroke: 0.1rem #592d84;
  text-transform: uppercase;
  color: var(--clr-default-100);
  transform: translate(-50%, -50%);
}

.section__item--mark {
  position: relative;
  padding-left: 2rem;
  font-size: clamp(
    1.6rem,
    1.3090909091rem + 0.9090909091vw,
    2.4rem
  );
}

.section__item--mark::before {
  content: "";
  position: absolute;
  left: 0.6rem;
  top: 0.9rem;
  border-radius: 50%;
  width: 0.3rem;
  height: 0.3rem;
  background-color: var(--clr-default-100);
}

.section__item--mark + li {
  margin-top: 1.5rem;
}

.help__list {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.license__title,
.license__text {
  text-align: center;
}

.license__btn {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  max-width: 30rem;
}

.details .section__btn,
.lotteries .section__btn,
.bonus .section__btn,
.offer .section__btn {
  display: flex;
  margin-top: 5rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 3rem;
}

.offer .section__btn {
  max-width: 40rem;
}

.bonus__content td:first-child,
.bonus__content th:first-child {
  background-color: initial;
}

.bonus__content tr::before {
  display: none;
}

.bonus__content thead {
  position: relative;
  color: rgba(255, 255, 255, 0.66);
}

.bonus__content thead::before {
  content: "";
  position: absolute;
  left: 2rem;
  bottom: 0;
  z-index: 1;
  border-radius: 1.5rem;
  width: calc(100% - 4rem);
  height: 0.1rem;
  background-color: rgba(255, 255, 255, 0.11);
}

.center {
  text-align: center;
}

.menu__list {
  display: flex;
  align-items: center;
}

.menu__btn {
  margin-top: auto;
  width: 100%;
}

.menu__item a {
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 110%;
  color: var(--clr-default-100);
  transition: color var(--transition);
}

.menu__item:not(:last-child) {
  margin-right: 2.5rem;
}

.logo {
  margin-right: 1.5rem;
  max-width: 18rem;
}

.burger {
  display: none;
}

.header {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-left: 1.5rem;
  border: 0.2rem solid #8b3fd6;
  border-radius: 1.2rem;
  padding-right: 3rem;
  padding-left: 3rem;
  min-width: 21rem;
  min-height: 6.8rem;
  font-weight: 700;
  font-size: clamp(
    1.8rem,
    1.5818181818rem + 0.6818181818vw,
    2.4rem
  );
  line-height: 80%;
  text-transform: uppercase;
  color: var(--clr-default-100);
  background-color: rgba(139, 63, 214, 0.05);
  transition: background-color var(--transition);
}

.footer {
  position: relative;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.footer::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.2rem;
  background-color: rgba(255, 255, 255, 0.15);
}

.footer .section__bg {
  top: initial;
  bottom: 0;
}

.footer__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero {
  padding-top: 8rem;
}

.hero__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hero__info {
  margin-right: 1.5rem;
  max-width: 60rem;
}

.hero__img {
  width: 100%;
  max-width: 58.4rem;
}

.hero__img img {
  border-radius: 2.5rem;
}

.hero .section__btn {
  margin-top: 3rem;
}

.hero__content {
  margin-top: 10rem;
}

.online__img {
  max-width: 121.6rem;
}

.online__img img {
  border-radius: 2.5rem;
}

.online__title {
  margin-top: 5rem;
  margin-bottom: 3rem;
}

.online .section__table th {
  color: rgba(255, 255, 255, 0.66);
}

.online .section__table td:first-child,
.online .section__table th:first-child {
  background-color: initial;
}

.online .section__table tr::before {
  display: none;
}

.online .section__table thead {
  position: relative;
  color: rgba(255, 255, 255, 0.66);
}

.online .section__table thead::before {
  content: "";
  position: absolute;
  left: 2rem;
  bottom: 0;
  z-index: 1;
  border-radius: 1.5rem;
  width: calc(100% - 4rem);
  height: 0.1rem;
  background-color: rgba(255, 255, 255, 0.11);
}

.registration {
  position: relative;
}

.registration .section__bg {
  top: 60rem;
}

.registration__list {
  margin-top: 6rem;
}

.registration__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.registration__item-info {
  width: 100%;
  max-width: 60rem;
}

.registration__item .section__text {
  margin-bottom: initial;
}

.registration__item + .registration__item {
  margin-top: 5rem;
}

.registration__img {
  margin-left: 3rem;
  width: 100%;
  max-width: 59.6rem;
}

.registration__img img {
  border-radius: 2.5rem;
}

.mobile__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile__info {
  width: 100%;
  max-width: 70rem;
}

.mobile__list {
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
}

.mobile__list .section__text {
  margin-bottom: initial;
}

.mobile__img {
  margin-right: 1.5rem;
  max-width: 44.3rem;
}

.mobile__img .section__img-text {
  left: -5rem;
  min-width: 60rem;
  font-weight: 800;
  font-size: clamp(
    1rem,
    -2.0545454545rem + 9.5454545455vw,
    9.4rem
  );
  transform: translateY(-50%) rotate(82.4deg);
}

.mobile__item {
  flex: 0 0 49%;
}

.welcome__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5rem;
}

.welcome__title {
  margin-bottom: 5rem;
}

.welcome__info {
  margin-right: 1.5rem;
  width: 100%;
  max-width: 60rem;
}

.welcome__info .section__text:last-child {
  margin-bottom: initial;
}

.welcome__img {
  max-width: 38.8rem;
}

.welcome__content tr::before {
  display: none;
}

.welcome__content td:first-child,
.welcome__content th:first-child {
  width: 22%;
}

.welcome__content td:nth-child(2),
.welcome__content th:nth-child(2) {
  width: 30%;
}

.welcome__content td:nth-child(3),
.welcome__content th:nth-child(3) {
  width: 18%;
}

.welcome__content td:last-child,
.welcome__content th:last-child {
  width: 30%;
}

.welcome__content thead {
  position: relative;
  color: rgba(255, 255, 255, 0.66);
}

.welcome__content thead::before {
  content: "";
  position: absolute;
  left: 2rem;
  bottom: 0;
  z-index: 1;
  border-radius: 1.5rem;
  width: calc(100% - 4rem);
  height: 0.1rem;
  background-color: rgba(255, 255, 255, 0.11);
}

.vip {
  position: relative;
}

.vip .section__bg {
  top: -10rem;
}

.vip__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5rem;
}

.vip__img {
  width: 100%;
  max-width: 70.1rem;
}

.vip__text {
  margin-top: initial;
  margin-bottom: initial;
  margin-left: 3rem;
  max-width: 50rem;
}

.game {
  position: relative;
}

.game .section__bg {
  top: -50rem;
}

.game__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.game .section__list--mark {
  margin-top: 3rem;
  font-size: clamp(
    1.8rem,
    1.5818181818rem + 0.6818181818vw,
    2.4rem
  );
}

.game .section__item--mark {
  padding-left: 3rem;
}

.game .section__item--mark::before {
  width: 0.5rem;
  height: 0.5rem;
}

.game .section__item--mark + .section__item--mark {
  margin-top: 3rem;
}

.game__info {
  margin-right: 1.5rem;
  max-width: 50rem;
}

.game__img {
  position: absolute;
  right: -40rem;
  top: 0;
  max-width: 81.8rem;
}

.game__img img {
  border-radius: 2.5rem;
}

.promotions__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5rem;
}

.promotions__img {
  max-width: 84.4rem;
}

.promotions__info {
  margin-right: 1.5rem;
  max-width: 50rem;
}

.plus {
  position: relative;
  padding-top: 6rem;
}

.plus .section__bg {
  top: -5rem;
}

.plus .section__btn {
  display: flex;
  margin-top: 5rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 30rem;
}

.plus__wrapper {
  display: flex;
  justify-content: space-between;
}

.plus__left,
.plus__right {
  flex: 0 0 49%;
}

.plus__list {
  margin-top: 3rem;
}

.plus__item {
  display: flex;
  font-size: clamp(
    1.8rem,
    1.5818181818rem + 0.6818181818vw,
    2.4rem
  );
  line-height: 150%;
}

.plus__item-img {
  margin-right: 1rem;
  width: 100%;
  max-width: 4.4rem;
}

.plus__item + .plus__item {
  margin-top: 2.5rem;
}

.live__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.live__img {
  margin-right: 3rem;
  width: 100%;
  max-width: 69.6rem;
}

.live__img img {
  border-radius: 2.5rem;
}

.live__info {
  width: 100%;
  max-width: 60rem;
}

.live .section__text {
  margin-bottom: initial;
}

.faq__list {
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(3, 1fr);
  list-style: none;
  margin-top: 5rem;
}

.faq__item p {
  margin-top: 1.5rem;
  font-size: clamp(
    1.6rem,
    1.4545454545rem + 0.4545454545vw,
    2rem
  );
}

.application {
  padding-bottom: 14rem;
}

.application__wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  margin-bottom: 5rem;
}

.application__left,
.application__right {
  flex: 0 0 49%;
}

.application__title {
  margin-bottom: 2.5rem;
}

.application .section__btn {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  max-width: 30rem;
}

.payment {
  position: relative;
}

.payment .section__bg {
  top: 5rem;
}

.payment__title {
  margin-top: 5rem;
  margin-bottom: 3rem;
}

.payment__content .section__table {
  min-width: 60rem;
}

.payment__content tr::before {
  display: none;
}

.payment__content thead {
  position: relative;
  color: rgba(255, 255, 255, 0.66);
}

.payment__content thead::before {
  content: "";
  position: absolute;
  left: 2rem;
  bottom: 0;
  z-index: 1;
  border-radius: 1.5rem;
  width: calc(100% - 4rem);
  height: 0.1rem;
  background-color: rgba(255, 255, 255, 0.11);
}

@media (min-width: 75.0625em) {
  .menu__btn {
    display: none;
  }
}

@media (max-width: 75em) {
  .section__content {
    padding: 2rem;
  }

  .menu__list {
    flex-direction: column;
    margin: auto;
    padding-block: 4rem;
  }

  .menu__item a {
    display: inline-block;
    font-size: 2.4rem;
    text-align: center;
  }

  .menu__item:not(:last-child) {
    margin-right: 1.5rem;
  }

  .menu__item:not(:last-child) {
    margin-right: initial;
    margin-bottom: 3rem;
  }

  .menu {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    overflow: auto;
    width: 100%;
    height: 100%;
    max-width: 32rem;
    background-color: var(--clr-default-900);
    transition: right var(--transition);
  }

  .menu--active {
    right: 0;
    transition: right var(--transition);
  }

  .burger {
    position: relative;
    z-index: 15;
    display: block;
    width: 2.5rem;
    height: 2rem;
    background-color: transparent;
  }

  .burger::after,
  .burger::before,
  .burger span {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 0.4rem;
    background-color: var(--clr-default-100);
  }

  .burger::before {
    top: 0;
    transition: transform var(--transition), top var(--transition);
  }

  .burger::after {
    bottom: 0;
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger span {
    top: 0.8rem;
    transition: transform var(--transition);
  }

  .burger--active::before {
    top: 0.8rem;
    transform: rotate(45deg);
    transition: transform var(--transition), top var(--transition);
  }

  .burger--active::after {
    bottom: 0.8rem;
    transform: rotate(-45deg);
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger--active span {
    transform: scale(0);
    transition: transform var(--transition);
  }

  .header__btn {
    margin-left: initial;
  }

  .header--hide {
    display: none;
  }

  .registration__img {
    margin-left: 1.5rem;
  }

  .mobile__img .section__img-text {
    left: -8rem;
  }

  .vip__text {
    margin-left: 1.5rem;
  }

  .live__img {
    margin-right: 1.5rem;
  }

  .faq__list {
    grid-gap: 2rem;
  }
}

@media (max-width: 62em) {
  .section__content {
    padding: 1rem;
  }

  .section__table tr::before {
    left: 1.5rem;
    width: calc(100% - 3rem);
  }

  .section__table tr td,
  .section__table tr th {
    padding: 1.5rem;
  }

  .bonus__content thead::before {
    left: 1.5rem;
    width: calc(100% - 3rem);
  }

  .hero__wrapper {
    flex-direction: column-reverse;
  }

  .hero__info {
    margin-right: initial;
    margin-bottom: 3rem;
    max-width: initial;
  }

  .online .section__table thead::before {
    left: 1.5rem;
    width: calc(100% - 3rem);
  }

  .registration__list {
    margin-top: 3rem;
  }

  .registration__item {
    flex-direction: column;
  }

  .registration__item-info {
    max-width: initial;
  }

  .registration__item + .registration__item {
    margin-top: 3rem;
  }

  .registration__img {
    margin-top: 2rem;
    margin-left: initial;
  }

  .mobile__wrapper {
    display: block;
  }

  .mobile__info {
    max-width: initial;
  }

  .mobile__img {
    display: none;
  }

  .welcome__content thead::before {
    left: 1.5rem;
    width: calc(100% - 3rem);
  }

  .vip__wrapper {
    flex-direction: column;
    margin-top: 3rem;
  }

  .vip__text {
    margin-top: 3rem;
    margin-left: initial;
    max-width: initial;
  }

  .game__wrapper {
    flex-direction: column;
  }

  .game__info {
    margin-right: initial;
    margin-bottom: 2rem;
    max-width: initial;
  }

  .game__img {
    position: relative;
    right: initial;
  }

  .promotions__wrapper {
    flex-direction: column-reverse;
    margin-top: 3rem;
  }

  .promotions__info {
    margin-right: initial;
    margin-bottom: 2rem;
    max-width: initial;
  }

  .live__wrapper {
    flex-direction: column-reverse;
  }

  .live__img {
    margin-top: 2rem;
    margin-right: initial;
  }

  .live__info {
    max-width: initial;
  }

  .faq__list {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 3rem;
  }

  .payment__content thead::before {
    left: 1.5rem;
    width: calc(100% - 3rem);
  }
}

@media (max-width: 36em) {
  .section__btn {
    width: 100%;
    min-width: initial;
    min-height: 6rem;
  }

  .license__btn {
    max-width: initial;
  }

  .details .section__btn,
  .lotteries .section__btn,
  .bonus .section__btn,
  .offer .section__btn {
    margin-top: 3rem;
    max-width: initial;
  }

  .plus .section__btn {
    max-width: initial;
  }

  .faq__list {
    display: block;
  }

  .faq__item + .faq__item {
    margin-top: 2rem;
  }

  .application__wrapper {
    display: block;
  }

  .application__left + .application__right {
    margin-top: 2.5rem;
  }

  .application .section__btn {
    max-width: initial;
  }
}

@media (max-width: 68.75em) {
  .mobile__img .section__img-text {
    left: -12rem;
  }
}

@media (max-width: 47.9375em) {
  .section__pt140 {
    padding-top: 7rem;
  }

  .section__text {
    margin-bottom: 2.5rem;
  }

  .header {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .footer {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .hero {
    padding-top: 4rem;
  }

  .hero__content {
    margin-top: 4rem;
  }

  .online__title {
    margin-top: 3rem;
  }

  .mobile__list {
    display: block;
    margin-top: 3rem;
  }

  .mobile__item + .mobile__item {
    margin-top: 3rem;
  }

  .welcome__wrapper {
    display: block;
    margin-top: 3rem;
  }

  .welcome__title {
    margin-bottom: 2.5rem;
  }

  .welcome__info {
    margin-right: initial;
    max-width: initial;
  }

  .welcome__img {
    display: none;
  }

  .plus {
    padding-top: 3rem;
  }

  .plus .section__btn {
    margin-top: 3rem;
  }

  .plus__wrapper {
    flex-direction: column;
  }

  .plus__left + .plus__right {
    margin-top: 3rem;
  }

  .plus__item-img {
    max-width: 2.6rem;
  }

  .application {
    padding-bottom: 7rem;
  }

  .application__wrapper {
    margin-bottom: 3rem;
  }

  .payment__title {
    margin-top: 3rem;
  }
}

@media (hover) {
  .section__btn:hover {
    box-shadow: 1px 1px 25px rgba(140, 64, 214, 0.25), 1px 1px 24px rgba(0, 0, 0, 0.25);
    transition: box-shadow var(--transition);
  }

  .menu__item a:hover {
    color: #8200ff;
    transition: color var(--transition);
  }

  .header__btn:hover {
    background-color: #8b3fd6;
    transition: background-color var(--transition);
  }
}