.vip {
  position: relative;

  .section__bg {
    top: -10rem;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5rem;

    @media (max-width: em(992)) {
      flex-direction: column;
      margin-top: 3rem;
    }
  }

  &__img {
    width: 100%;
    max-width: 70.1rem;
  }

  &__text {
    margin-top: initial;
    margin-bottom: initial;
    margin-left: 3rem;
    max-width: 50rem;

    @media (max-width: em(1200)) {
      margin-left: 1.5rem;
    }

    @media (max-width: em(992)) {
      margin-top: 3rem;
      margin-left: initial;
      max-width: initial;
    }
  }
}
