.welcome {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5rem;

    @media (max-width: em(767)) {
      display: block;
      margin-top: 3rem;
    }
  }

  &__title {
    margin-bottom: 5rem;

    @media (max-width: em(767)) {
      margin-bottom: 2.5rem;
    }
  }

  &__info {
    margin-right: 1.5rem;
    width: 100%;
    max-width: 60rem;

    @media (max-width: em(767)) {
      margin-right: initial;
      max-width: initial;
    }

    .section__text:last-child {
      margin-bottom: initial;
    }
  }

  &__img {
    max-width: 38.8rem;

    @media (max-width: em(767)) {
      display: none;
    }
  }

  &__content {
    tr {
      &::before {
        display: none;
      }
    }

    td,
    th {
      &:first-child {
        width: 22%;
      }
      &:nth-child(2) {
        width: 30%;
      }
      &:nth-child(3) {
        width: 18%;
      }
      &:last-child {
        width: 30%;
      }
    }

    thead {
      position: relative;

      color: rgba(255, 255, 255, 0.66);

      &::before {
        content: "";
        position: absolute;
        left: 2rem;
        bottom: 0;
        z-index: 1;
        border-radius: 1.5rem;
        width: calc(100% - 4rem);
        height: 0.1rem;
        background-color: rgba(255, 255, 255, 0.11);

        @media (max-width: em(992)) {
          left: 1.5rem;
          width: calc(100% - 3rem);
        }
      }
    }
  }
}
