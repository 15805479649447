$burgerMQ: em(1200);

.header {
  padding-top: 3rem;
  padding-bottom: 3rem;

  @media (max-width: em(767)) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-left: 1.5rem;
    border: 0.2rem solid #8b3fd6;
    border-radius: 1.2rem;
    padding-right: 3rem;
    padding-left: 3rem;
    min-width: 21rem;
    min-height: 6.8rem;
    font-weight: 700;
    font-size: fluid-font(2.4rem, 1.8rem);
    line-height: 80%;
    text-transform: uppercase;
    color: var(--clr-default-100);
    background-color: rgba(139, 63, 214, 0.05);
    transition: background-color var(--transition);

    @media (hover) {
      &:hover {
        background-color: #8b3fd6;
        transition: background-color var(--transition);
      }
    }

    @media (max-width: $burgerMQ) {
      margin-left: initial;
    }
  }

  &--hide {
    @media (max-width: em(1200)) {
      display: none;
    }
  }
}
