.application {
  padding-bottom: 14rem;

  @media (max-width: em(767)) {
    padding-bottom: 7rem;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    margin-bottom: 5rem;

    @media (max-width: em(767)) {
      margin-bottom: 3rem;
    }

    @media (max-width: em(576)) {
      display: block;
    }
  }

  &__left,
  &__right {
    flex: 0 0 49%;
  }

  &__left + &__right {
    @media (max-width: em(576)) {
      margin-top: 2.5rem;
    }
  }

  &__title {
    margin-bottom: 2.5rem;
  }

  .section__btn {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    max-width: 30rem;

    @media (max-width: em(576)) {
      max-width: initial;
    }
  }
}
