.registration {
  position: relative;

  .section__bg {
    top: 60rem;
  }

  &__list {
    margin-top: 6rem;

    @media (max-width: em(992)) {
      margin-top: 3rem;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(992)) {
      flex-direction: column;
    }

    &-info {
      width: 100%;
      max-width: 60rem;

      @media (max-width: em(992)) {
        max-width: initial;
      }
    }

    .section__text {
      margin-bottom: initial;
    }
  }

  &__item + &__item {
    margin-top: 5rem;

    @media (max-width: em(992)) {
      margin-top: 3rem;
    }
  }

  &__img {
    margin-left: 3rem;
    width: 100%;
    max-width: 59.6rem;

    @media (max-width: em(1200)) {
      margin-left: 1.5rem;
    }

    @media (max-width: em(992)) {
      margin-top: 2rem;
      margin-left: initial;
    }

    img {
      border-radius: 2.5rem;
    }
  }
}
