.mobile {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(992)) {
      display: block;
    }
  }

  &__info {
    width: 100%;
    max-width: 70rem;

    @media (max-width: em(992)) {
      max-width: initial;
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;

    .section__text {
      margin-bottom: initial;
    }

    @media (max-width: em(767)) {
      display: block;
      margin-top: 3rem;
    }
  }

  &__img {
    margin-right: 1.5rem;
    max-width: 44.3rem;

    @media (max-width: em(992)) {
      display: none;
    }

    .section__img {
      &-text {
        left: -5rem;
        min-width: 60rem;
        font-weight: 800;
        font-size: fluid-font(9.4rem, 1rem);
        transform: translateY(-50%) rotate(82.4deg);

        @media (max-width: em(1200)) {
          left: -8rem;
        }

        @media (max-width: em(1100)) {
          left: -12rem;
        }
      }
    }
  }

  &__item {
    flex: 0 0 49%;
  }

  &__item + &__item {
    @media (max-width: em(767)) {
      margin-top: 3rem;
    }
  }
}
